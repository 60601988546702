import React from 'react'
import './hamburger.scss'


const Hamburger = ({ isOpen, openMenu}) => {
    return (
 <div className={`hamburger hamburger--arrow ${isOpen ? 'is-active' : ''}`} tabIndex="0"
       aria-label="Menu" role="button" aria-controls="navigation" aria-expanded={isOpen} onClick={openMenu}>
    <div className="hamburger-box">
      <div className="hamburger-inner"></div>
    </div>
  </div>
    )
}

export default Hamburger
