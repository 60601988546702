import React from 'react'
import krotex from '../img/krotex.svg'

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer has-text-white-ter" style={{ padding: '20px', background: '#A38C88', margin: '20px 0' }}>
        <div className="content has-text-white-ter">
        <img src={krotex} height="50" width="300" alt="krotex"/>
          <p style={{ fontSize: 14 }}>Producent: KROTEX PHARM Sp. z o.o. Sp. k.<br></br>ul. Dostępna 56, 01-490 Warszawa KROTEX</p>
          </div>
      </footer>
    )
  }
}

export default Footer
