import React from 'react'
import { Link } from 'gatsby'
import Headroom from 'react-headroom'
import Hamburger from './hamburger/Hamburger'
import Logo from './svg/logo'
import insta from '../img/instagram.svg'
import facebook from '../img/facebook.svg'


class Navbar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false
    }
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      }
    )
  }

  render() {
    return (
      <>
      <div className={`navbar-fullscreen ${this.state.active ? 'menuIsActive' : 'menuIsInactive'}`} >
              <Link className="navbar-item" to="/#home" onClick={() => this.toggleHamburger()}>
                Strona Główna
              </Link>
              <Link className="navbar-item" to="/#articles" onClick={() => this.toggleHamburger()}>
                Artykuły
              </Link>
              <Link className="navbar-item" to="/#recipes" onClick={() => this.toggleHamburger()}>
                Przepisy
              </Link>
              <Link className="navbar-item" to="/#importantInfo" onClick={() => this.toggleHamburger()}>
                Ważne informacje
              </Link>
            </div>
      <Headroom>
      <nav 
        className="navbar is-transparent paddingTop20"
        role="navigation"
        aria-label="main-navigation"
      >
        <div className="container mnavbar">
          <div className="navbar-brand has-text-centered">
            <div style={{ marginRight: 10}}>
          <Hamburger isOpen={this.state.active} openMenu={() => this.toggleHamburger()} />
            </div>
            <Link to="/">
            <div style={{display: 'flex', alignItems: 'center', height: '100%'}} >
            <Logo />
            </div>
            </Link>
          </div>
          <div className="navbar-end">
            <a href="https://www.facebook.com/Stress-Under-Control-2286574518275667/" >
            <img src={facebook} alt="facebook" width="32" height="32" style={{marginRight: '10px' }}/>
            </a>
            <a href="https://www.instagram.com/stress_under_control/" >
            <img src={insta} alt="ashwangada insta" width="48" height="48" />
            </a>
          </div>
          </div>
      </nav>
      </Headroom>
      
          </>
    )
  }
}

export default Navbar
